import React, { useState } from 'react';
import Arrow from '../../assets/Icons/Arrow.svg';

interface DuvidasListProps extends React.HTMLAttributes<HTMLDivElement> {
    tipoDuvida?: 'transportador' | 'embarcador' | 'geral';
    listaDuvidas: Duvida[];
}

export type Duvida = {
    duvida: string;
    descricao: string;
    tipoDuvida: 'transportador' | 'embarcador' | 'geral';
};

const DuvidasList = ({
    listaDuvidas,
    tipoDuvida = 'geral',
}: DuvidasListProps) => {
    const [selectedDuvida, setSelectedDuvida] = useState<string | null>(null);

    const listaAtualizada = listaDuvidas.filter((duvida, i) => {
        return (
            duvida.tipoDuvida === tipoDuvida ||
            duvida.tipoDuvida === 'geral' ||
            tipoDuvida === 'geral'
        );
    });

    return (
        <div>
            <ol>
                {listaAtualizada.map(({ duvida, descricao }, index) => {
                    return (
                        <li
                            key={index}
                            className="flex min-h-24 flex-col border-t-2 pt-6"
                        >
                            <button
                                onClick={() =>
                                    setSelectedDuvida(
                                        duvida === selectedDuvida
                                            ? null
                                            : duvida
                                    )
                                }
                                className="flex w-[45.6rem] justify-between"
                            >
                                <p className="text-left font-sans text-2xl text-secondary">
                                    {duvida}
                                </p>

                                <img
                                    src={Arrow}
                                    data-selected={selectedDuvida === duvida}
                                    className="h-6 w-6 transition-all duration-300 data-selected:-rotate-180"
                                    alt=""
                                />
                            </button>
                            <p
                                data-selected={selectedDuvida === duvida}
                                className="mt-6 w-[45.6rem] overflow-hidden text-left font-sans text-lg
                                    text-secondary transition-all duration-1000 data-selected:visible data-selected:max-h-[50rem] data-!selected:max-h-0 data-!selected:duration-150"
                            >
                                {descricao}
                            </p>
                        </li>
                    );
                })}
            </ol>
        </div>
    );
};

export default DuvidasList;
