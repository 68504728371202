import FormBackground from '../../assets/Home/FormBackground.svg';
import HeaderNormal from './Primitivos/HeaderNormal';
import { useEffect } from 'react';

const Form = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @ts-ignore
            if (window.hbspt) {
                // @ts-ignore
                window.hbspt.forms.create({
                    portalId: '5665308',
                    formId: '7da32dd8-afba-4beb-9eab-65ff7a9ba2f8',
                    target: '#hubspotForm',
                });
            }
        });
    }, []);

    return (
        <div
            style={{
                backgroundImage: `url(${FormBackground})`,
                zIndex: 10,
                position: 'relative',
            }}
            className="z-10 mx-auto flex w-4/5 items-center justify-between rounded-lg bg-cover bg-center p-10"
            id="formulario"
        >
            <div className="flex-1 p-10">
                <HeaderNormal texto="Fale com a gente." />
                <h2 className="font-sans text-2xl font-thin text-white">
                    Deixe seu contato que te retornamos
                </h2>
            </div>
            <div
                id="hubspotForm"
                className="flex h-full flex-1 flex-col gap-y-4"
            ></div>
        </div>
    );
};

export default Form;
