import React from 'react';
import { twMerge } from 'tailwind-merge';

interface HeaderNormalProps extends React.HTMLAttributes<HTMLHeadingElement> {
    texto: string;
}

const HeaderNormal = ({ texto, className }: HeaderNormalProps) => {
    return (
        <h1 className={twMerge('font-russo text-5xl text-white', className)}>
            {texto}
        </h1>
    );
};

export default HeaderNormal;
