import React from 'react';
import { twMerge } from 'tailwind-merge';

interface CardContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
}

const CardContainer = ({ children, ...props }: CardContainerProps) => {
    return (
        <div className={twMerge('grid gap-6', props.className)}>{children}</div>
    );
};

export default CardContainer;
