import InfoBox from '../Genericos/InfoBox';
import MainContent from '../Genericos/MainContent';
import MainImg from '../../assets/Home/MainImg.png';
import HeaderPequeno from '../Genericos/Primitivos/HeaderPequeno';
import HeaderNormal from '../Genericos/Primitivos/HeaderNormal';

const HomeMainContent = () => {
    return (
        <MainContent image={MainImg} contentHeight="min-h-[95vh]">
            <div className="row-start-4 row-end-12">
                <div className="mb-10 w-[50%]">
                    <HeaderPequeno texto="Plataforma" className="mb-4" />
                    <HeaderNormal
                        texto=" Somos um marketplace de fretes, onde você simula, faz cotação, contrata e organiza seus fretes em uma só plataforma."
                        className="w-3/4"
                    />
                </div>
                <div className="row-start-2 flex gap-8 pr-[20%]">
                    <InfoBox
                        text='Cadastrar'
                        title="Transportador"
                        content="Cadastre sua tabela de frete com a gente."
                        href="/transportador"
                        className="col-start-1"
                    />
                    <InfoBox
                        text='Cotar Frete'
                        title="Embarcador"
                        content="Cote frete para suas entregas aqui."
                        href="/embarcador"
                        className="col-start-2"
                    />
                </div>
            </div>
        </MainContent>
    );
};

export default HomeMainContent;
