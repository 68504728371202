import React from 'react';
import Instagram from '../../assets/Icons/Instagram.svg';
import Linkedin from '../../assets/Icons/Linkedin.svg';
import Whatsapp from '../../assets/Icons/Whatsapp.svg';
import { twMerge } from 'tailwind-merge';

interface InfoProps extends React.HTMLAttributes<HTMLDivElement> {}

const Info = ({ ...props }: InfoProps) => {
    return (
        <div className={twMerge('mx-auto -mt-48 w-[98vw]', props.className)}>
            <div className="z-0 rounded-lg bg-primary p-8 pt-56">
                <div className="flex flex-col">
                    <div className="mb-4 w-full md:w-1/2">
                        <div className="mb-2">
                            <p className="font-light">
                                <a
                                    href="https://5665308.fs1.hubspotusercontent-na1.net/hubfs/5665308/Termo%20de%20Uso%20e%20Condi%C3%A7%C3%B5es%20Gerais%202.0.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Termos de uso condições gerais
                                </a>
                            </p>
                            <p className="font-light">
                                <a
                                    href="https://lincros.com/politica-de-privacidade-lincros/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Política de privacidade LGPD
                                </a>
                            </p>
                        </div>
                    </div>
                    <p className="font-bold">
                        R. São Paulo, 640 – 89012-011 Victor Konder, Blumenau –
                        SC – (47) 3237-5816
                    </p>

                    <div className="-my-10 mb-1 flex w-full items-center justify-end gap-2 ">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/fretemax/"
                        >
                            <img
                                src={Instagram}
                                alt="Instagram Logo"
                                className="min-h-6 min-w-6 cursor-pointer object-cover"
                            ></img>
                        </a>

                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/company/fretemax/"
                        >
                            <img
                                src={Linkedin}
                                alt="Linkedin Logo"
                                className="min-h-6 min-w-6 cursor-pointer object-cover"
                            ></img>
                        </a>

                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://api.whatsapp.com/send/?phone=5547992355448"
                        >
                            <img
                                src={Whatsapp}
                                alt="Whatsapp Logo"
                                className="min-h-6 min-w-6 cursor-pointer object-cover"
                            ></img>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Info;
