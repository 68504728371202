import Card from '../Card/Card';
import CardContainer from '../Card/CardContainer';
import DinheiroIcon from '../../assets/Icons/Dinheiro.svg';
import VolumeIcon from '../../assets/Icons/Volume.svg';
import MacbookIcon from '../../assets/Embarcador/EmbarcadorMacBook.png';
import ContratoIcon from '../../assets/Icons/Contrato.svg';
import ChecklistIcon from '../../assets/Icons/Checklist.svg';
import ComputerChecklistIcon from '../../assets/Icons/ComputerChecklist.svg';
import DashboardIcon from '../../assets/Icons/Dashboard.svg';
import HeaderPequeno from '../Genericos/Primitivos/HeaderPequeno';
import HeaderGrande from '../Genericos/Primitivos/HeaderGrande';
import Button from '../Genericos/Primitivos/Button';

const EmbarcadorCardContainer = () => {
    return (
        <>
            <CardContainer className="mx-auto mb-16 mt-4 h-[30rem] w-[99%] grid-cols-2">
                <Card className="col-span-1 h-[30rem] place-items-center">
                    <img src={MacbookIcon} alt="" className="" />
                </Card>
                <Card
                    className="col-span-1"
                    heading="Simule, faça cotação, contrate e organize seus fretes em uma só plataforma."
                    description="Simplifique sua gestão logística: em uma única plataforma, simule, faça cotações, contrate e organize seus fretes de maneira eficiente e intuitiva. Concentre todas as etapas do transporte em um só lugar, eliminando processos e ganhando agilidade na gestão logística de sua empresa."
                />
            </CardContainer>

            <div className="mx-auto mb-4 flex w-4/5 place-content-between">
                <div className="w-1/2">
                    <HeaderPequeno
                        texto="EMBARCADOR"
                        className="text-secondary"
                    />
                    <HeaderGrande
                        texto="Por que escolher o FreteMax?"
                        className="text-secondary"
                    />
                </div>
                <Button
                    texto="Eu quero contratar"
                    tipoBotao="primario"
                    className="h-16 w-80 text-2xl"
                    onClick={() => {
                        console.log('Scroll');
                        document
                            .getElementById('formulario')
                            ?.scrollIntoView({ behavior: 'smooth' });
                    }}
                />
            </div>

            <CardContainer className="mx-auto mb-16 h-2row w-[80%] grid-cols-3 grid-rows-2">
                <Card
                    className="col-span-1"
                    heading="Tabela de fretes competitiva"
                    description="Talvez seu ERP não atenda com ofertas de fretes suficientes, e com o FreteMax, disponibilizamos uma gama maior de tabelas competitivas."
                    icon={DashboardIcon}
                />
                <Card
                    className="col-span-1"
                    heading="Simulação de fretes"
                    description="Você não precisa se preocupar em encontrar a carga, nós encontramos pra você."
                    icon={ChecklistIcon}
                />
                <Card
                    className="col-span-1"
                    heading="Liberdade de uso"
                    description="Você contrata o frete quando quiser pela plataforma, e não necessariamente precisa ter contrato fixo."
                    icon={ComputerChecklistIcon}
                />
                <Card
                    className="col-span-1"
                    heading="Sem quantidades minimas"
                    description="Você não precisa ter grande volume de entregas para poder contratar um frete."
                    icon={VolumeIcon}
                />
                <Card
                    className="col-span-1"
                    heading="Pagamentos flexiveis"
                    description="Você pode atuar na modalidade de pagamento que encaixar melhor para o seu negócio, tanto pré ou pós-pago."
                    icon={DinheiroIcon}
                />
                <Card
                    className="col-span-1"
                    heading="Cotação simplificada"
                    description="Pode realizar a cotação sem obrigatoriamente ter integração com ERP."
                    icon={ContratoIcon}
                />
            </CardContainer>
        </>
    );
};

export default EmbarcadorCardContainer;
