import TransportadorCardContainer from '../components/Transportador/TransportadorCardContainer';
import PageForm from '../components/Genericos/PageForm';
import InfoForm from '../components/Gerais/InfoForm';
import TransportadorMainContent from '../components/Transportador/TransportadorMainContent';
import { useEffect } from 'react';

function Transportador() {
    useEffect(() => {
        document.title = 'Fretemax - Transportador';
    }, []);
    return (
        <>
            <PageForm>
                <TransportadorMainContent />
            </PageForm>
            <TransportadorCardContainer />
            <InfoForm />
        </>
    );
}

export default Transportador;
