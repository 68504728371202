import PageForm from '../components/Genericos/PageForm';
import HomeMainContent from '../components/Home/HomeMainContent';
import FretemaxInfoBox from '../components/Home/FretemaxInfoBox';
import UniqueSellingPointList from '../components/Home/UniqueSellingPointList';
import ListaDeClientes from '../components/Home/ListaDeClientes';
import HomeQuemSomos from '../components/Home/HomeQuemSomos';
import DuvidasFrequentes from '../components/Home/DuvidasFrequentes';
import InfoForm from '../components/Gerais/InfoForm';
import { useEffect } from 'react';

function Home() {
    useEffect(() => {
        document.title = 'Fretemax';
    }, []);
    return (
        <>
            <PageForm>
                <HomeMainContent />
                <FretemaxInfoBox />
                <UniqueSellingPointList className="pb-14" />
                <ListaDeClientes className="pb-14" />
            </PageForm>
            <HomeQuemSomos className="py-14" />
            <DuvidasFrequentes className="pb-14" />
            <InfoForm />
        </>
    );
}

export default Home;
