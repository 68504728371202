import React from 'react';
import { twMerge } from 'tailwind-merge';

interface HeaderFinoProps extends React.HTMLAttributes<HTMLHeadingElement> {
    texto: string;
}

const HeaderPequeno = ({ texto, className }: HeaderFinoProps) => {
    return (
        <h3
            className={twMerge(
                'w-fit font-sans font-light uppercase tracking-[0.5rem] text-white',
                className
            )}
        >
            {texto}
        </h3>
    );
};

export default HeaderPequeno;
