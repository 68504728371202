import React from 'react';
import Navbar from '../Gerais/Navbar';
import { twMerge } from 'tailwind-merge';

interface MainContentProps {
    image: string;
    contentHeight?: string;
    children?: React.ReactNode;
}

const bgClasses = 'bg-no-repeat bg-cover bg-right';

const MainContent = ({ image, contentHeight, children }: MainContentProps) => {
    contentHeight = contentHeight ?? 'h-[50rem]';

    return (
        <div
            className={twMerge(
                'mx-auto grid w-[99%] grid-rows-12 overflow-hidden rounded-lg px-14 pt-8',
                contentHeight,
                bgClasses
            )}
            style={{ backgroundImage: `url(${image})` }}
        >
            <Navbar />
            {children}
        </div>
    );
};

export default MainContent;
