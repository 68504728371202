import { twMerge } from 'tailwind-merge';
import Button from '../Genericos/Primitivos/Button';
import HeaderNormal from '../Genericos/Primitivos/HeaderNormal';
import DuvidasList, { Duvida } from './DuvidasList';
import { useState } from 'react';

interface DuvidasFrequentesProps extends React.HTMLAttributes<HTMLDivElement> {}

const DuvidasFrequentes = ({ className }: DuvidasFrequentesProps) => {
    const [tipoDuvida, setTipoDuvida] = useState<
        'geral' | 'transportador' | 'embarcador' | undefined
    >('geral');
    const DuvidasFrequentes: Duvida[] = [
        {
            duvida: 'O que é o FreteMax?',
            descricao:
                'É uma plataforma de gestão e intermediação de fretes, é como um marketplace de fretes. O Fretemax apoia tanto embarcadores que precisam contratar um frete, quanto transportadores que gostariam de disponibilizar suas tabelas de frete para ofertar aos embarcadores da base Fretemax.',
            tipoDuvida: 'geral',
        },
        {
            duvida: 'O Fretemax é uma transportadora?',
            descricao:
                'Não. O Fretemax é uma plataforma de gestão e intermediação de fretes. Todo o transporte é realizado por parceiros homologados e contratados pela Fretemax.',
            tipoDuvida: 'geral',
        },
        {
            duvida: 'Sou embarcador, preciso ter contrato com as transportadoras para utilizar o Fretemax? ',
            descricao:
                'Não. O Fretemax negocia diretamente com as transportadoras, permitindo que nossos clientes utilizem os serviços sem a necessidade de contrato direto.',
            tipoDuvida: 'embarcador',
        },
        {
            duvida: 'Sou embarcador, ao contratar o Fretemax, a transportadora irá exigir um mínimo de envio por mês?',
            descricao:
                'Não. O FreteMax não exige mínimos de envios por mês. A plataforma foi desenvolvida para atender as demandas de pequenas e médias empresas.',
            tipoDuvida: 'embarcador',
        },
        {
            duvida: 'Sou embarcador, preciso falar com a transportadora, ou o Fretemax negocia meu envio direto com a transportadora?',
            descricao:
                'O Fretemax negocia fretes diretamente com as transportadoras. Como trabalhamos com grandes volumes em nossa base de embarcadores, conseguimos negociar preços mais competitivos e oferecer condições especiais aos nossos clientes.',
            tipoDuvida: 'embarcador',
        },
        {
            duvida: 'Sou transportador, consigo homologar minha transportadora no Fretemax?',
            descricao:
                'Entre em contato com nosso time comercial para obter mais informações sobre nosso modelo de negócio e se tornar um parceiro FreteMax!',
            tipoDuvida: 'transportador',
        },
        {
            duvida: 'Sou transportador, como faço para cadastrar minha tabela com o Fretemax?',
            descricao:
                'Após negociação com o time Fretemax, recebemos sua tabela de frete, cadastramos ela na plataforma. A partir desse momento, suas ofertas de frete estarão disponíveis para nossa base de embarcadores',
            tipoDuvida: 'transportador',
        },
        {
            duvida: 'Sou transportador, como serei informado quando ocorrer uma escolha de meu frete?',
            descricao:
                'Disponibilizamos para os embarcadores a opção de enviar uma Ordem de Coleta ao transportador selecionado para que ele fique ciente, programe a coleta conforme necessidade e efetue o transporte. Além disso, disponibilizamos um portal para que o transportador acompanhe as solicitações.',
            tipoDuvida: 'transportador',
        },
    ];

    return (
        <div
            className={twMerge(
                'mx-auto flex w-[90%] flex-wrap justify-around',
                className
            )}
        >
            <div className="mb-10 grid h-fit gap-10 2xl:h-96 2xl:w-72">
                <HeaderNormal
                    texto="Dúvidas Frequentes"
                    className="text-secondary"
                />
                <p className="font-sans text-2xl text-secondary">
                    Possui alguma outra dúvida? Entre em contato com nossa
                    equipe e responderemos todas.
                </p>

                <div className="flex gap-6">
                    <Button
                        texto="Sou transportador"
                        tipoBotao="terciario"
                        className="h-12 w-1/2 text-base"
                        onClick={() => {
                            setTipoDuvida('transportador');
                        }}
                    />
                    <Button
                        texto="Sou embarcador"
                        tipoBotao="terciario"
                        className="h-12 w-1/2 text-base"
                        onClick={() => {
                            setTipoDuvida('embarcador');
                        }}
                    />
                </div>

                <Button
                    texto="Entrar em contato"
                    tipoBotao="primario"
                    className="h-14 w-80 text-2xl"
                    onClick={() => {
                        console.log('Scroll');
                        document
                            .getElementById('formulario')
                            ?.scrollIntoView({ behavior: 'smooth' });
                    }}
                />
            </div>
            <div>
                <DuvidasList
                    listaDuvidas={DuvidasFrequentes}
                    tipoDuvida={tipoDuvida}
                />
            </div>
        </div>
    );
};

export default DuvidasFrequentes;
