import React from 'react';

interface PageFormProps {
    children?: React.ReactNode;
}

const PageForm: React.FC<PageFormProps> = ({ children }) => {
    return <div className="w-full bg-background-image pt-2">{children}</div>;
};

export default PageForm;
