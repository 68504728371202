import React from 'react';

interface UniqueSellingPonitProps {
    icone: string;
    valor: number;
    mainText: string;
    altText: string;
}

const UniqueSellingPonit: React.FC<UniqueSellingPonitProps> = ({
    icone,
    valor,
    mainText,
    altText,
}) => {
    return (
        <div className="flex h-14 w-96  items-center gap-6">
            <img src={icone} alt="" className="col-start-1" />
            <p className="col-start-2 font-russo text-6xl text-primary">
                {valor}
            </p>
            <div>
                <p className="h-fit w-fit font-russo text-3xl text-primary">
                    {mainText}
                </p>
                <p className="h-fit w-fit font-russo text-lg text-secondary">
                    {altText}
                </p>
            </div>
        </div>
    );
};

export default UniqueSellingPonit;
