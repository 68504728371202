import PageForm from '../components/Genericos/PageForm';
import InfoForm from '../components/Gerais/InfoForm';
import EmbarcadorMainContent from '../components/Embarcador/EmbarcadorMainContent';
import EmbarcadorCardContainer from '../components/Embarcador/EmbarcadorCardContainer';
import { useEffect } from 'react';

function Transportador() {
    useEffect(() => {
        document.title = 'Fretemax - Embarcador';
    }, []);
    return (
        <>
            <PageForm>
                <EmbarcadorMainContent />
            </PageForm>
            <EmbarcadorCardContainer />
            <InfoForm />
        </>
    );
}

export default Transportador;
