import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface ListaDeParceirosProps extends React.HTMLAttributes<HTMLDivElement> {}

const ListaDeParceiros = ({ className }: ListaDeParceirosProps) => {
    const [svgKeys, setSvgKeys] = useState<string[]>([]);

    useEffect(() => {
        const svgs = require.context('../../assets/Parceiros', true, /\.svg$/);

        setSvgKeys(
            svgs.keys().map((key) => {
                return svgs(key);
            })
        );
    }, []);

    return (
        <div
            className={twMerge(
                'flex w-full flex-wrap place-content-evenly gap-x-10 gap-y-6 pr-6',
                className
            )}
        >
            {svgKeys?.map((key) => {
                return <img src={key} key={key} alt="" className="max-h-12" />;
            })}
        </div>
    );
};

export default ListaDeParceiros;
