import React from 'react';
import HeaderPequeno from '../Genericos/Primitivos/HeaderPequeno';
import HeaderGrande from '../Genericos/Primitivos/HeaderGrande';
import { twMerge } from 'tailwind-merge';
import MacBookImg from '../../assets/Home/MacBookImg.png';
import NumeredList from '../Genericos/Primitivos/NumeredList';
import ListaDeParceiros from './ListaDeParceiros';

interface HomeQuemSomosProps extends React.HTMLAttributes<HTMLDivElement> {}

const HomeQuemSomos = ({ className }: HomeQuemSomosProps) => {
    const itensLista = [
        'Acesso à tabelas de frete competitivas com o apoio da base Lincros.',
        'Sem obrigatoriedade de contrato fixo e sem quantidades mínimas.',
        'Pagamentos flexíveis com modalidade pré e pós-paga.',
        'Cotação simplificada sem a exigência em ter integração com um ERP.',
    ];

    return (
        <div>
            <div
                className={twMerge(
                    'mb-24 flex gap-y-10 px-20 max-xl:flex-wrap',
                    className
                )}
            >
                <div className="w-1/3">
                    <HeaderPequeno
                        texto="Nosso objetivo"
                        className="text-secondary"
                    />
                    <HeaderGrande
                        texto="Até onde queremos chegar?"
                        className="text-secondary"
                    />
                </div>
                <p className="self-center px-10 font-sans text-3xl font-light text-secondary">
                    Queremos ser o principal gateway para intermediação de frete
                    fracionado e de compartilhamento de cargas do
                    Brasil.
                </p>
            </div>

            <div className="pb-24">
                <div className="relative z-10 mx-auto flex w-[90%] flex-wrap place-content-around rounded-3xl bg-background-image-macbook bg-cover bg-no-repeat max-xl:justify-center max-xl:px-10 xl:px-10 xl:pt-20">
                    <img
                        src={MacBookImg}
                        alt="Imagem Macbook com o site FreteMax"
                        className="min-w-[500px] object-scale-down xl:max-w-[50%]"
                    />
                    <NumeredList
                        items={itensLista}
                        className="self-center xl:max-w-[50%]"
                        itemsClassName="mb-14"
                    />
                </div>
                <div className="relative -z-0 mx-auto -mt-6 flex h-fit w-[90%] items-center rounded-b-3xl bg-secondary py-12">
                    <p className="pl-14 pt-6 font-russo text-3xl text-white">
                        Parceiros Fretemax:
                    </p>
                    <ListaDeParceiros className="items-center pt-6" />
                </div>
            </div>
        </div>
    );
};

export default HomeQuemSomos;
