import React from 'react';
import Button from './Primitivos/Button';
import { twMerge } from 'tailwind-merge';

interface InfoBoxProps {
    text: string;
    title: string;
    content: string;
    href: string;
    className?: string;
}

const InfoBox = ({text, title, content, href, className }: InfoBoxProps) => {
    return (
        <div
            className={twMerge(
                'grid w-80 grid-rows-3 items-center justify-items-center rounded-xl px-8 py-6 outline outline-1 outline-primary',
                className
            )}
        >
            <h2 className="row-start-1 font-sans text-3xl font-bold text-white">
                {title}
            </h2>
            <h1 className="row-start-2 w-[90%] text-center font-sans text-2xl text-white">
                {content}
            </h1>

            <Button
                tipoBotao="primario"
                className="row-start-3 h-12 w-56"
                texto={text}
                toUrl={href}
            />
        </div>
    );
};

export default InfoBox;
