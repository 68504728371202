import React from 'react';
import { twMerge } from 'tailwind-merge';

interface NumeredListProps extends React.HTMLAttributes<HTMLOListElement> {
    items: string[];
    itemsClassName?: string;
}

const NumeredList = ({
    items,
    className,
    itemsClassName,
}: NumeredListProps) => {
    return (
        <ol className={twMerge('list-inside', className)}>
            {items.map((item, index) => (
                <li
                    key={index}
                    className={twMerge('text-3xl ', itemsClassName)}
                >
                    <div className="flex gap-x-2 pr-40 font-sans font-black text-primary">
                        {index + 1 < 10
                            ? '0' + (index + 1) + '. '
                            : index + 1 + '. '}
                        <div className="font-russo font-normal text-secondary">
                            {item}
                        </div>
                    </div>
                </li>
            ))}
        </ol>
    );
};

export default NumeredList;
