import MainContent from '../Genericos/MainContent';
import TransportadorMainImg from '../../assets/Transportador/TransportadorMainImg.png';
import HeaderNormal from '../Genericos/Primitivos/HeaderNormal';

const TransportadorMainContent = () => {
    return (
        <MainContent image={TransportadorMainImg} contentHeight="h-[70vh]">
            <div className="row-start-4 row-end-12">
                <div className="mb-10 w-[50%]">
                    <HeaderNormal texto="Transportador" className="w-3/4" />

                    <p className="pt-6 font-sans text-3xl font-light text-white">
                        Cadastre sua tabela de frete conosco e maximize o
                        alcance das oportunidades de negócio.
                    </p>
                </div>
            </div>
        </MainContent>
    );
};

export default TransportadorMainContent;
