import MainContent from '../Genericos/MainContent';
import TransportadorMainImg from '../../assets/Embarcador/EmbarcadorMainImg.png';
import HeaderNormal from '../Genericos/Primitivos/HeaderNormal';

const EmbarcadorMainContent = () => {
    return (
        <MainContent image={TransportadorMainImg} contentHeight="h-[70vh]">
            <div className="row-start-4 row-end-12">
                <div className="mb-10 w-[50%]">
                    <HeaderNormal texto="Embarcador" className="w-3/4" />

                    <p className="pt-6 font-sans text-3xl font-light text-white">
                        Cote frete para suas entregas aqui e encontre o melhor
                        valor e prazo para suas mercadorias
                    </p>
                </div>
            </div>
        </MainContent>
    );
};

export default EmbarcadorMainContent;
