import React from 'react';
import Lampada from '../../assets/Home/Lampada.svg';

const FretemaxInfoBox = () => {
    return (
        <div className="mx-auto -mt-2 flex h-32 w-[99%] items-center gap-8 rounded-b-lg bg-primary pl-20">
            <img src={Lampada} alt="" />
            <p className="font-sans  text-4xl font-bold text-white">
            O Fretemax nasceu dentro da Lincros, que hoje, possui grande
             expertise em atender a indústria.
            </p>
        </div>
    );
};

export default FretemaxInfoBox;
