import React from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    texto: string;
    tipoBotao: 'primario' | 'secundario' | 'terciario';
    toUrl?: string;
}

const Button = ({
    texto,
    tipoBotao,
    className,
    toUrl,
    ...props
}: ButtonProps) => {
    const determineButtonClass = (tipoBotao: string) => {
        switch (tipoBotao) {
            case 'primario':
                return 'bg-primary text-white';
            case 'secundario':
                return 'bg-white';
            case 'terciario':
                return 'bg-transparent border-primary text-secondary border-2';
            default:
                return '';
        }
    };

    if (toUrl) {
        return (
            <Link
                to={toUrl}
                className={twMerge(
                    'flex items-center justify-center rounded-3xl font-sans text-xl',
                    determineButtonClass(tipoBotao),
                    className
                )}
            >
                {texto}
            </Link>
        );
    }

    return (
        <button
            className={twMerge(
                'btn rounded-3xl font-sans text-xl',
                determineButtonClass(tipoBotao),
                className
            )}
            {...props}
        >
            {texto}
        </button>
    );
};

export default Button;
