import Card from '../Card/Card';
import CardContainer from '../Card/CardContainer';
import CarteiraIcon from '../../assets/Icons/Carteira.svg';
import LupaIcon from '../../assets/Icons/Lupa.svg';
import DinheiroIcon from '../../assets/Icons/Dinheiro.svg';
import RelogioIcon from '../../assets/Icons/Relogio.svg';
import DashboardIcon from '../../assets/Icons/Dashboard.svg';
import HeaderPequeno from '../Genericos/Primitivos/HeaderPequeno';
import HeaderGrande from '../Genericos/Primitivos/HeaderGrande';
import Button from '../Genericos/Primitivos/Button';

const TransportadorCardContainer = () => {
    return (
        <>
            <CardContainer className="mx-auto mb-16 mt-4 h-row w-[99%] grid-cols-3">
                <Card
                    className="col-span-1"
                    heading="Cadastre suas tabelas de frete com a gente e tenha acesso aos maiores embarcadores do Brasil."
                />
                <Card
                    className="col-span-2"
                    description="Tenha acesso a base com mais de 230 embarcadores, entre eles as maiores indústrias do Brasil. Faça negócios com esta base de empresas."
                />
            </CardContainer>

            <div className="mx-auto mb-4 flex w-4/5 place-content-between">
                <div className="w-1/2">
                    <HeaderPequeno
                        texto="TRANSPORTADOR"
                        className="text-secondary"
                    />
                    <HeaderGrande
                        texto="Por que cadastrar sua tabela com o FreteMax?"
                        className="text-secondary"
                    />
                </div>
                <Button
                    texto="Eu quero contratar"
                    tipoBotao="primario"
                    className="h-16 w-80 text-2xl"
                    onClick={() => {
                        console.log('Scroll');
                        document
                            .getElementById('formulario')
                            ?.scrollIntoView({ behavior: 'smooth' });
                    }}
                />
            </div>

            <CardContainer className="mx-auto mb-16 h-2row w-[80%] grid-cols-3 grid-rows-2">
                <Card
                    className="col-span-2"
                    heading="Mais visibilidade para receber ofertas de frete."
                    description="O FreteMax encontra a carga pra você."
                    icon={LupaIcon}
                />
                <Card
                    className="col-span-1"
                    heading="Aumente seus lucros."
                    description="Aumento de receita ao realizar mais trabalhos, pois contará com mais oportunidades de oferta de carga."
                    icon={DinheiroIcon}
                />
                <Card
                    className="col-span-1"
                    heading="Economize dinheiro e tempo."
                    description="Reduzirá custos administrativos ao gerenciar a documentação de maneira centralizada."
                    icon={RelogioIcon}
                />
                <Card
                    className="col-span-1"
                    heading="Ecossistema  tecnológico."
                    description="Será mais competitivo pois estará apoiado em tecnologias inovadoras e integradas com parceiros."
                    icon={DashboardIcon}
                />
                <Card
                    className="col-span-1"
                    heading="Pagamento organizado e facilitado."
                    description="Todas as transações serão realizadas na plataforma e você recebe o pagamento com mais rapidez."
                    icon={CarteiraIcon}
                />
            </CardContainer>
        </>
    );
};

export default TransportadorCardContainer;
