import React from 'react';
import Info from '../Genericos/Info';
import Form from '../Genericos/Form';

interface InfoFormProps extends React.HTMLAttributes<HTMLDivElement> {}

const InfoForm = (props: InfoFormProps) => {
    return (
        <>
            <Form />
            <Info />
        </>
    );
};

export default InfoForm;
