import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
    icon?: string;
    heading?: string;
    description?: string;
}

const Card = ({
    icon,
    heading,
    description,
    className,
    ...props
}: CardProps) => {
    return (
        <div
            className={twMerge(
                'flex rounded-2xl bg-gray px-4 pt-10',
                className
            )}
        >
            {icon && <img src={icon} alt="" className="size-7" />}
            <div className="self-center">
                {heading && (
                    <h2 className="font-russo text-4xl font-normal text-secondary">
                        {heading}
                    </h2>
                )}
                {description && (
                    <p className="font-sans text-2xl font-light text-secondary">
                        {description}
                    </p>
                )}
            </div>
            {props.children}
        </div>
    );
};

export default Card;
