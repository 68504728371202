import UniqueSellingPonit from '../Genericos/UniqueSellingPonit';
import LogoCargas from '../../assets/Home/LogoCargas.svg';
import LogoTransportados from '../../assets/Home/LogoTransportados.svg';
import LogoValorFrete from '../../assets/Home/LogoValorFrete.svg';
import { twMerge } from 'tailwind-merge';

const UniqueSellingPointList = ({
    className,
}: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <div className={twMerge('px-20 pt-14', className)}>
            <h3 className="mb-7 h-fit w-fit font-russo text-xl font-normal text-secondary">
                Já operacionalizamos
            </h3>
            <div className="flex flex-wrap place-content-between gap-10">
                <UniqueSellingPonit
                    icone={LogoCargas}
                    mainText="milhões"
                    altText="de cargas"
                    valor={5}
                />
                <UniqueSellingPonit
                    icone={LogoValorFrete}
                    mainText="bilhões"
                    altText="em valor de frete"
                    valor={12}
                />
                <UniqueSellingPonit
                    icone={LogoTransportados}
                    mainText="bi/kg"
                    altText="transportados"
                    valor={20}
                />
            </div>
            <div></div>
        </div>
    );
};

export default UniqueSellingPointList;
