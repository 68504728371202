import React from 'react';
import { twMerge } from 'tailwind-merge';

interface HeaderGrandeProps extends React.HTMLAttributes<HTMLHeadingElement> {
    texto: string;
}

const HeaderGrande = ({ texto, className }: HeaderGrandeProps) => {
    return (
        <h1 className={twMerge('font-russo text-7xl text-white', className)}>
            {texto}
        </h1>
    );
};

export default HeaderGrande;
