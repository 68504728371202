import { Link } from 'react-router-dom';
import FreteMaxLogo from '../../assets/FreteMaxLogo.svg';
import Button from '../Genericos/Primitivos/Button';
import { url } from 'inspector';

const Navbar = () => {
    return (
        <div className="row-start-1 flex h-fit w-full place-content-between items-center gap-20">
            <Link to={'/'}>
                <img className="h-8" src={FreteMaxLogo} alt="FreteMax" />
            </Link>
            <div className="flex h-fit flex-wrap items-center gap-x-20 gap-y-10">
                <Link
                    className="font-sans text-xl font-normal text-white data-[active=true]:font-bold data-[active=true]:underline"
                    data-active={window.location.pathname === '/'}
                    to={'/'}
                >
                    Home
                </Link>
                <Link
                    className="font-sans text-xl font-normal text-white data-[active=true]:font-bold data-[active=true]:underline"
                    data-active={window.location.pathname === '/transportador'}
                    to={'/transportador'}
                >
                    Transportador
                </Link>
                <Link
                    className="font-sans text-xl font-normal text-white data-[active=true]:font-bold data-[active=true]:underline"
                    data-active={window.location.pathname === '/embarcador'}
                    to={'/embarcador'}
                >
                    Embarcador
                </Link>
                <Button
                    tipoBotao="secundario"
                    texto="Fale conosco"
                    onClick={() => {
                        console.log('Acesse sua conta');
                    }}
                    className="h-12 w-56"
                />
                <Button
                    tipoBotao="primario"
                    texto="Acesse sua conta"
                    className="h-12 w-56"
                    toUrl="https://app.fretemax.com"
                />
            </div>
        </div>
    );
};

export default Navbar;
