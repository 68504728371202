import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface ListaDeClientesProps extends React.HTMLAttributes<HTMLDivElement> {}

const ListaDeClientes = ({ className }: ListaDeClientesProps) => {
    const [svgKeys, setSvgKeys] = useState<string[]>([]);

    useEffect(() => {
        const svgs = require.context('../../assets/Clientes', true, /\.svg$/);

        setSvgKeys(
            svgs.keys().map((key) => {
                return svgs(key);
            })
        );
    }, []);

    return (
        <div className={twMerge('flex px-20', className)}>
            <p className="w-28 text-wrap font-russo font-normal">
                Clientes parceiros Lincros:
            </p>
            <div className="flex w-full flex-wrap place-content-around gap-6">
                {svgKeys?.map((key) => {
                    return (
                        <img src={key} key={key} alt="" className="h-16 w-36" />
                    );
                })}
            </div>
        </div>
    );
};

export default ListaDeClientes;
